export default [
  {
    "path": "/nps",
    "component": 'Layout',
    "hidden": false,
    "alwaysShow": true,
    "meta": {
      "title": "Nps",
      "icon": "el-icon-star-off"
    },
    "children": [
      {
        "path": "recoverit",
        "name": "NpsRecoverit",
        "component": 'nps/recoverit/index',
        "meta": {
          "title": "Recoverit",
          "icon": ""
        }
      },
      {
        "path": "recoverit/v2",
        "name": "NpsRecoveritNew",
        "component": 'nps/recoverit/v2/index',
        "meta": {
          "title": "Recoverit-New",
          "icon": ""
        }
      },
      {
        "path": "repairit",
        "name": "NpsRepairit",
        "component": 'nps/repairit/index',
        "meta": {
          "title": "Repairit",
          "icon": ""
        }
      },
      {
        "path": "wxrecovery",
        "name": "NpsWxRecovery",
        "component": 'nps/wxrecovery/index',
        "meta": {
          "title": "WxRecovery",
          "icon": ""
        }
      }
    ]
  },
  {
    "path": '/product',
    "component": 'Layout',
    "hidden": false,
    "alwaysShow": true,
    "meta": {
      "title": '产品配置',
      "icon": 'el-icon-setting'
    },
    "children": [
      {
        "path": 'recoverit',
        "name" : 'Recoverit',
        "hidden": false,
        "component": 'product/recoverit/index',
        "meta": {
          "title": 'Recoverit',
          "icon": ''
        }
      },
      {
        "path": 'repairitFeature',
        "name" : 'repairitFeature',
        "component": 'product/repairitFeature',
        "hidden": false,
        "meta": {
          "title": 'repairit feature',
          "icon": ''
        }
      },
      {
        "path": 'repairit',
        "name" : 'Repairit',
        "component": 'product/repairit',
        "hidden": false,
        "meta": {
          "title": 'Repairit',
          "icon": ''
        }
      },
      {
        "path": 'wxrecovery',
        "name" : 'Wxrecovery',
        "component": 'product/wxrecovery',
        "hidden": false,
        "meta": {
          "title": 'Wxrecovery',
          "icon": ''
        }
      },
      {
        "path": 'ubackit',
        "name" : 'Ubackit',
        "component": 'product/ubackit',
        "hidden": false,
        "meta": {
          "title": 'Ubackit',
          "icon": ''
        }
      },
      {
        "path": 'app',
        "name" : 'App',
        "component": 'product/app',
        "hidden": false,
        "meta": {
          "title": 'App',
          "icon": ''
        }
      },
    ]
  },
  {
    "path": '/feedback',
    "component": "Layout",
    "hidden": false,
    "meta": { "title": '用户反馈', "icon": 'el-icon-bell'},
    "children": [
      {
        "path": '',
        "name": 'Feedback',
        "hidden": false,
        "component": "feedback",
        "meta": { "title": '用户反馈', "icon": 'el-icon-bell'}
      },
    ]
  },
  {
    "path": '/settings',
    "component": "Layout",
    "hidden": false,
    "meta": { "title": '个人设置', "icon": 'el-icon-s-custom'},
    "children": [
      {
        "path": '',
        "name": 'Settings',
        "component": 'settings',
        "meta": { "title": '个人设置', "icon": 'el-icon-s-custom'}
      }
    ]
  },
]