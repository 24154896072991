<template>
  <el-menu
    :default-active="$route.path"
    background-color="#002033"
    text-color="#fff"
    active-text-color="#fed04b"
    router
    :collapse="isCollapse"
  >
    <!-- 左边菜单子组件 -->
    <sidebar-item
      v-for="(route, index) in sidebarRouters"
      :key="route.path + index"
      :item="route"
      :base-path="route.path"
      :is-collapse = "isCollapse"
    />
    <!-- <el-menu-item index="/">
        <i class="el-icon-s-home"></i>
        <span slot="title">首页</span>
    </el-menu-item> -->

    <!-- <el-submenu index="/nps">
      <template slot="title">
        <i class="el-icon-star-off"></i>
        <span>NPS</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/nps/nps-recoverit">Recoverit</el-menu-item>
        <el-menu-item index="/nps/v2/nps-recoverit">Recoverit-New</el-menu-item>
        <el-menu-item index="/nps/nps-repairit">Repairit</el-menu-item>
        <el-menu-item index="/nps/nps-wxrecovery">聊天记录恢复</el-menu-item>
      </el-menu-item-group>
    </el-submenu>


    <el-submenu index="/product">
      <template slot="title">
        <i class="el-icon-setting"></i>
        <span>产品配置</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/product/recoverit">Recoverit</el-menu-item>
        <el-menu-item index="/product/repairit">Repairit</el-menu-item>
        <el-menu-item index="/product/wxrecovery">WxRecovery</el-menu-item>
        <el-menu-item index="/product/ubackit">UBackit</el-menu-item>
        <el-menu-item index="/product/app">App</el-menu-item>
      </el-menu-item-group>
    </el-submenu>


    <el-menu-item index="/feedback">
      <i class="el-icon-bell"></i>
      <span slot="title">用户反馈</span>
    </el-menu-item>

    <el-menu-item index="/settings">
      <i class="el-icon-s-custom"></i>
      <span slot="title">个人设置</span>
    </el-menu-item>

    <el-menu-item index="/management" v-if="user.user_name === 'admin'">
      <i class="el-icon-user"></i>
      <span slot="title">用户管理</span>
    </el-menu-item>

    <el-submenu v-for="(item,index) in navData" :key="index" :index="item.path">
     <template slot="title">
       <i class="el-icon-star-off"></i>
       <span>{{item.name}}</span>
     </template>
     <el-menu-item-group v-if="item.children && item.children.length > 0" >
       <el-menu-item v-for = "(it,key) in item.children" :key="key" index="/product/recoverit">
         {{it.name}}
       </el-menu-item>
     </el-menu-item-group>
   </el-submenu> -->

  </el-menu>

</template>

<script>
import navData from "@/utils/navData"; // 导航复用数据
import {mapGetters} from "vuex"
import SidebarItem from "../Sidebar/SidebarItem";
export default {
  name: "AppAside",
  components: { SidebarItem },
  computed: {
    ...mapGetters([
      'sidebarRouters',
      ]),
  },
  props: [
    'is-collapse',
    'user',
  ],
  data() {
    return {
       navData
    }
  },
  methods: {
  }
}
</script>

<style scoped>
  /*.aside-container {*/
  /*  width: 200px;*/
  /*}*/
</style>
