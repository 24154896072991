<template>
  <el-container class="layout-container">

    <el-aside class="aside" :width="(isCollapse)?'auto':'210px'">
      <app-aside
        class="aside-menu"
        :is-collapse="isCollapse"
        :user="user"
      />
    </el-aside>


    <el-container>
      <el-header class="header">
        <div>
          <i
            :class="{
              'el-icon-s-fold': isCollapse,
              'el-icon-s-unfold': !isCollapse
            }"
            @click="isCollapse = !isCollapse"
          ></i>
          <span>公共配置管理系统</span>
        </div>
        <el-dropdown>
          <div class="avatar-wrap">
            <img class="user-avatar" :src="avatar">
            <span>{{user.nick_name}}</span>
            &nbsp;
            <i class="el-icon-arrow-down"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="$router.push('/settings')">设置</el-dropdown-item>
            <el-dropdown-item
              @click.native="OnLogOut"
            >退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

      </el-header>

      <el-main class="main">
        <!--子路由出口-->
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AppAside from './components/aside'
import { getUserProfile } from '@/api/user'

export default {
  name: "Layout",
  components: {
    AppAside,
  },
  computed: {
    ...mapGetters([
      'avatar',
    ]),
  },
  data (){
    return {
      user: {},
      isCollapse: false // 侧边菜单栏的展开状态
    }
  },
  created() {
    this.loadUserProfile()
  },
  methods: {
    // 获取用户资料, 其他所有接口都需要授权才能请求使用
    loadUserProfile() {
      getUserProfile().then(res => {
        this.user = res.data
      })
    },
    OnLogOut() {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          location.href = '/';
        })
      }).catch(() => {});
    }
  }
}
</script>

<style scoped lang="scss">
  .layout-container {
    position: fixed;
    height: 100%;
    width: 100%;
  }

  .aside {
    /*background-color: #01a1ff;*/
    .aside-menu {
      height: 100%;
    }
    .aside-menu:not(.el-menu--collapse) {
      width: 220;
      height: 100%;
    }
  }
  .header {
    //background-color: #b3c0d1;
    display: flex;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    .avatar-wrap {
      display: flex;
      align-items: center;
      cursor: pointer;
      .user-avatar {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
  }
  .main {
    background-color: #f6f6f6;
  }
</style>
