import request  from "@/utils/request";

export function login(user_name, password) {
  const data = {
    user_name, password
  }
  return request({
    url: '/user/v2/login',
    method: 'post',
    data: data // 请求体
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: '/user/v2/logout',
    method: 'post'
  })
}
