import request  from "@/utils/request";

// 获取用户资料
export function getUserProfile() {
  return request({
    url: '/user/v2/profile',
    method: 'get',
  })
}

// 获取用户列表
export function getUserList(data) {
  return request({
    url: '/user/v2/user/list',
    method: 'POST',
    data: data
  })
}

// 添加用户信息
export function addUserInfo(data) {
  return request({
    url: '/user/v2/user',
    method: 'post',
    data: data
  })
}

// 修改用户信息
export function updateUserInfo(data) {
  return request({
    url: '/user/v2/user',
    method: 'put',
    data: data
  })
}

// 删除用户信息
export function delUserInfo(user_name) {
  return request({
    // 路径参数需要在url中传递
    // 接口API路径中有 :xxx 格式字段，则需要传递路径参数
    method: 'DELETE',
    url:`/user/v2/user/${user_name}`
  })
}

// 重置用户密码
export function resetUserPwd(user_name, password) {
  const data = {
    user_name,
    password
  }
  return request({
    url: '/user/v2/user/reset_pwd',
    method: 'put',
    params: data
  })
}

export function activateUser(user_name) {
  const data = {
    user_name
  }
  return request({
    url: '/user/v2/user/activate',
    method: 'put',
    params: data
  })
}

// 修改用户
export function updateUserProfile(data) {
  return request({
    url: '/user/v2/profile/update',
    method: 'put',
    data: data
  })
}

// 更新用户密码
export function updateUserPwd(oldPassword, newPassword) {
  const data = {
    'old_password': oldPassword,
    'new_password': newPassword
  }
  return request({
    url: '/user/v2/profile/update_pwd',
    method: 'put',
    params: data
  })
}

// 用户头像上传
export function uploadAvatar(data) {
  return request({
    url: '/user/v2/profile/avatar',
    method: 'post',
    data: data
  })
}
