import Vue from 'vue'
import VueRouter from 'vue-router'

const Layout = () => import('@/layout')
const Login = () => import('@/views/login')
const Home = () => import('@/views/home')

// 安装插件
Vue.use(VueRouter)

// 创建VueRouter对象
// const routes = [
//   {
//     path: '/',
//     component: Layout,
//     redirect: 'home',
//     children: [
//       {
//         path: '',
//         component: Home,
//         name: 'Home',
//         // meta: { title: '首页', icon: 'dashboard', noCache: true, affix: true }
//       },
//       {
//         path: '/nps',
//         name: 'NPS',
//         component: Nps
//       },
//       {
//         path: '/feedback',
//         name: 'Feedback',
//         component: Feedback
//       },
//       {
//         path: '/nps-recoverit',
//         name: 'NpsRecoverit',
//         component: NpsRecoverit
//       },
//       {
//         path: '/v2/nps-recoverit',
//         name: 'NpsRecoveritNew',
//         component: NpsRecoveritNew
//       },
//       {
//         path: '/nps-repairit',
//         name: 'NpsRepairit',
//         component: NpsRepairit
//       },
//       {
//         path: '/nps-wxrecovery',
//         name: 'NpsWxRecovery',
//         component: NpsWxRecovery
//       },
//
//       {
//         path: '/recoverit',
//         name : 'Recoverit',
//         component: Recoverit
//       },
//       {
//         path: '/repairit',
//         name : 'Repairit',
//         component: Repairit
//       },
//       {
//         path: '/ubackit',
//         name : 'Ubackit',
//         component: Ubackit
//       },
//       {
//         path: '/wxrecovery',
//         name : 'WXRecovery',
//         component: WXRecovery
//       },
//       {
//         path: '/app',
//         name : 'App',
//         component: App
//       },
//     ]
//   },
//   {
//     path: '/',
//     component: Layout,
//     children: [
//       {
//         path: '/settings',
//         name: 'Settings',
//         component: Settings,
//       }
//     ]
//   },
//   {
//     path: '/login',
//     name: 'Login',
//     component: Login
//   },
//   {
//     path: '/',
//     name: 'Home',
//     component: Home
//   }
// ]

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    noCache: true                // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */

export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: 'home',
    children: [
      {
        path: '',
        component: (resolve) => require(['@/views/home'], resolve),
        name: 'Home',
        hidden: false,
        meta: { title: '首页', icon: 'el-icon-s-home', noCache: true, affix: true }
      }
    ]
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    hidden: true,
  }
]

// const accessRoutes = [
//   {
//     path: '/product',
//     component: Layout,
//     hidden: false,
//     children: [
//       {
//         path: '',
//         name: 'Product',
//         component: () => import('@/views/product'),
//       },
//       {
//         path: 'recoverit',
//         name : 'Recoverit',
//         component: () => import('@/views/product/recoverit')
//       },
//       {
//         path: 'repairit',
//         name : 'Repairit',
//         component: () => import('@/views/product/repairit')
//       },
//       {
//         path: 'ubackit',
//         name : 'Ubackit',
//         component: () => import('@/views/product/wxrecovery')
//       },
//       {
//         path: 'wxrecovery',
//         name : 'WXRecovery',
//         component: () => import('@/views/product/wxrecovery')
//       },
//       {
//         path: 'app',
//         name : 'App',
//         component: () => import('@/views/product/app')
//       },
//     ]
//   },
// ]

const roleRoutes = [
  {
    path: '/management',
    component: Layout,
    hidden: false,
    children: [
      {
        path: '',
        component: () => import('@/views/management'),
        name: '权限管理',
      }
    ]
  }
]

const router = new VueRouter({
  // 配置路由和组件之间的映射关系
  routes:constantRoutes,
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 })
})

import { getToken } from '@/utils/auth'
import NProgress from "nprogress";
import 'nprogress/nprogress.css'
import store from "@/store";
import {Message} from "element-ui";

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) 
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

// 路由导航守卫
// to： 指向的路由信息
// from: 来自哪里的路由信息
// next: 放行方法
// router.beforeEach((to, from, next) => {
//   // 判定是否已鉴权，
//   if (to.name !== 'Login' && !getToken()) next({ name: 'Login' })
//   else {
//     console.log(to);
//     next()
//   }
// })

const whiteList = ['/login']

router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  if (getToken()) {
    // to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      if (store.getters.roles.length === 0) {
        store.dispatch('GetInfo').then(res => {
          //router.addRoutes(accessRoutes) // 动态添加可访问路由表
          store.dispatch('GenerateRoutes', res.data.role_type).then( accessRoutes => {
            router.addRoutes(accessRoutes) 
            next({...to, replace: true})
          })
        }).catch(err => {
          store.dispatch('LogOut').then(() => {
            Message.error(err)
            next({ path: '/' })
          })
        })
      } else {
        next()
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next(`login`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})

// 将router对象传入到Vue实例
export default router
